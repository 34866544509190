export const Role = {
    Admin: 'Administrator',
    Manager: 'Manager',
    Blogger: 'Blogger',
    Office: 'Office',
    Foreman: 'Foreman',
    User: 'User'
}

export function hasRole(userRoles, role) {
    let has = false
    userRoles.forEach(ur => {
        if (ur.roleName == role) {
            has = true
        }
    })
    return has
}