import apiService from '@/_services/api-service'
import registryService from '@/_services/registry-service'
import store from "@/store"

export default {
  load() {

    const url = "menuitem"
    apiService.get(url, this.successCallback, this.errorCallback)
  },

  successCallback(result) {
    let menuItems = []
    const items = result.data
    // Get the items with no parents
    const parentItems = items.filter(i => i.parentId == undefined)
    parentItems.forEach(item => {
      item.subMenuItems = []
      menuItems.push(item)
    });
    menuItems.sort((a, b) => (a.order > b.order ? 1 : -1));
    // Now push the sub menu items on to their parent
    menuItems.forEach(item => {
      if(item.pageId > 0) {
        item.to = item.to + "/" + item.pageId
      }
      item.subMenuItems = items.filter(i => i.parentId == item.id)
      item.subMenuItems.sort((a, b) => (a.order > b.order ? 1 : -1));
    })

    store.commit("setMenuItems", menuItems)
  },

  errorCallback(error) {
    console.log('menu-service.load() Error: ', error)
  },

  getAppMenu(breakpoint) {
    let menuItems = store.getters.getMenuItems
    // console.log("menuItem.length: ", menuItems.length)
    let appItems = []
    menuItems.forEach(mi => {
      let appItem = JSON.parse(JSON.stringify(mi))
      if (appItem.breakpoint <= breakpoint) {
        if (this.passesRules(appItem)) {
          let subMenuItems = appItem.subMenuItems
          appItem.subMenuItems = []
          subMenuItems.forEach(sub => {
            if (this.passesRules(sub)) {
              appItem.subMenuItems.push(sub)
            }
          })
          appItems.push(appItem)
        }
      }
    })

    return appItems
  },

  getBottomMenu() {
    return store.getters.getMenuItems.filter(i => i.bottomShow == true)
  },

  getDrawerMenu() {
    let menuItems = store.getters.getMenuItems
    // console.log("menuItem.length: ", menuItems.length)
    let appItems = []
    menuItems.forEach(mi => {
      let appItem = JSON.parse(JSON.stringify(mi))
      if (this.passesRules(appItem)) {
        let subMenuItems = appItem.subMenuItems
        appItem.subMenuItems = []
        subMenuItems.forEach(sub => {
          if (this.passesRules(sub)) {
            appItem.subMenuItems.push(sub)
          }
        })
        appItems.push(appItem)
      }
    })

    return appItems
  },

  passesRules(item) {
    let LoggedIn = store.getters.getUser.id > 0
    let passes = false
    if (item.rules == null || item.rules.length == 0 || item.text == "Blog") {
      if(item.text == "Blog") {
        passes =  registryService.getBool('blog_include')
      } else {
        passes = true
      }
    } else {
      if (item.rules.includes("LoggedIn") && LoggedIn) {
        passes = true
      }
      if (item.rules.includes("LoggedOut") && !LoggedIn) {
        passes = true
      }
      if(item.rules.includes("Administrator") && store.getters.isAdministrator) {
        passes = true
      }
      if(item.rules.includes("Blogger") && store.getters.isBlogger) {
        passes = true
      }
      if(item.rules.includes("Manager") && store.getters.isManager) {
        passes = true
      }
      if(item.rules.includes("Office") && store.getters.isOffice) {
        passes = true
      }
      if(item.rules.includes("Foreman") && store.getters.isForeman) {
        passes = true
      }
    }
    //console.log('Item: ', item.text, "Rules: ", item.rules, "LoggedIn: ", LoggedIn, "Passes: ", passes)
    return passes
  }
};
