import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/_utility/role'
import store from "@/store"

Vue.use(VueRouter)
const commonTags = [
  {
    name: 'viewport',
    content: 'width=device-width,minimum-scale=1,maximum-scale=1',
  },
  {
    name: 'fragment',
    content: '!',
  },
  {
    name: 'robots',
    content: 'index, follow',
  },
  {
    name: 'googlebot',
    content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
  },
  {
    name: 'bingbot',
    content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
  },
  {
    name: 'charset',
    content: 'UTF-8',
  },
  {
    property: 'og:local',
    content: 'en_US',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:url',
    content: 'http://www.ransford-solutions.com/',
  },
  {
    property: 'og:site_name',
    content: 'Ransford Solutions',
  },
  {
    property: 'article:modified_time',
    content: '2021-07-14T00:00+00.00',
  },
  {
    name: 'twitter:card',
    content: 'summary',
  },
  // {
  //   name: 'google-site-verification',
  //   content: 'K3RgKjA18sPhdoTN5DU4TsX0vXJVDhBKrgjkUOX8fJU',
  // },
]

const routes = [
  {
    path: '/account/forgot',
    name: 'Forgot Password',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Forgot Password - Ransford Solutions',
      metaTags: [
        {
          property: 'og:title',
          content: 'Forgot Password - Ransford Solutions',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-forgot-password" */ '@/views/Account/Forgot.vue')
  },
  {
    path: '/account/login',
    name: 'Login',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Login - Ransford Solutions',
      metaTags: [
        {
          property: 'og:title',
          content: 'Login - Ransford Solutions',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-login" */ '@/views/Account/Login.vue')
  },
  {
    path: '/account/register',
    name: 'Register',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Register - Ransford Solutions',
      metaTags: [
        {
          property: 'og:title',
          content: 'Register - Ransford Solutions',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-register" */ '@/views/Account/Register.vue')
  },
  {
    path: '/account/reset',
    name: 'Reset Password',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Reset Password - Ransford Solutions',
      metaTags: [
        {
          property: 'og:title',
          content: 'Reset Password - Ransford Solutions',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-reset-password" */ '@/views/Account/Reset.vue')
  },
  {
    path: '/account/logout',
    name: 'Logout',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Logout - Ransford Solutions',
      metaTags: [
        {
          property: 'og:title',
          content: 'Logout - Ransford Solutions',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-logout" */ '@/views/Account/Logout.vue')
  },
  {
    path: '/account/roles',
    name: 'User Roles',
    includeInSiteMap: false,
    meta: { 
      authorize: [Role.Admin, Role.Manager],
      title: 'User Roles',
      metaTags: [
        {
          property: 'og:title',
          content: 'User Roles',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-userroles" */ '@/views/Account/UserRoles.vue')
  },
  {
    path: '/account/profile',
    name: 'Profile',
    includeInSiteMap: false,
    meta: { authorize: [Role.Admin, Role.Blogger, Role.Manager, Role.Office, Role.Foreman, Role.User] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Account/Profile.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: { 
      authorize: [],
      title: 'Blog',
      metaTags: [
        {
          property: 'og:title',
          content: 'Blog',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Blogs/Blog.vue')
  },
  {
    path: '/blog/list',
    name: 'Blog Posts',
    includeInSiteMap: true,
    meta: { 
      authorize: ["Administrator", "Blogger"],
      title: 'Blog Posts',
      metaTags: [
        {
          property: 'og:title',
          content: 'Blog Posts',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Blogs/List.vue')
  },
  {
    path: '/blog/edit/:id',
    name: 'Add-Edit Post',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator", "Blogger"],
      title: 'Edit Post',
      metaTags: [
        {
          property: 'og:title',
          content: 'Edit Post',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Blogs/EditBlog.vue')
  },
  {
    path: '/blog/:id',
    name: 'View Post',
    includeInSiteMap: true,
    meta: { 
      authorize: [],
      title: 'View Post',
      metaTags: [
        {
          property: 'og:title',
          content: 'View Post',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Blogs/ViewBlog.vue')
  },
  {
    path: '/image',
    name: 'Images',
    meta: { 
      authorize: [],
      title: 'Images',
      metaTags: [
        {
          property: 'og:title',
          content: 'Images',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Images/List.vue')
  },
  {
    path: '/image/:id',
    name: 'Image Detail',
    meta: { 
      authorize: [],
      title: 'Image Detail',
      metaTags: [
        {
          property: 'og:title',
          content: 'Image',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "image-edit" */ '@/views/Images/Image.vue')
  },
  {
    path: '/image/edit/:id',
    name: 'Image',
    meta: { 
      authorize: [],
      title: 'Image',
      metaTags: [
        {
          property: 'og:title',
          content: 'Image',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "image-edit" */ '@/views/Images/Edit.vue')
  },
  {
    path: '/video',
    name: 'Videos',
    meta: { 
      authorize: [],
      title: 'Videos',
      metaTags: [
        {
          property: 'og:title',
          content: 'Videos',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Video/List.vue')
  },
  {
    path: '/video/edit/:id',
    name: 'Video',
    meta: { 
      authorize: [],
      title: 'Video',
      metaTags: [
        {
          property: 'og:title',
          content: 'Video',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "video-edit" */ '@/views/Video/Edit.vue')
  },
  {
    path: '/video/:id',
    name: 'Video Detail',
    meta: { 
      authorize: [],
      title: 'Video Detail',
      metaTags: [
        {
          property: 'og:title',
          content: 'Video',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "video" */ '@/views/Video/Video.vue')
  },
  {
    path: '/logo',
    name: 'Logos',
    meta: { 
      authorize: [],
      title: 'Logos',
      metaTags: [
        {
          property: 'og:title',
          content: 'Logos',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Logos/List.vue')
  },
  {
    path: '/logo/:id',
    name: 'Logo',
    meta: { 
      authorize: [],
      title: 'Logo',
      metaTags: [
        {
          property: 'og:title',
          content: 'Logo',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "logo-edit" */ '@/views/Logos/Edit.vue')
  },
  {
    path: '/registry',
    name: 'Registry Settings',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Registry Settings',
      metaTags: [
        {
          property: 'og:title',
          content: 'Registry Settings',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "account-profile" */ '@/views/Registry/List.vue')
  },
  {
    path: '/registry/:id',
    name: 'Registry Settings',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Registry Settings',
      metaTags: [
        {
          property: 'og:title',
          content: 'Registry Settings',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "registry-edit" */ '@/views/Registry/Edit.vue')
  },
  {
    path: '/menus',
    name: 'Menu Items',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Menu Items',
      metaTags: [
        {
          property: 'og:title',
          content: 'Menu Items',
        },
        {
          property: 'og:description',
          content: 'List of the Menu Items',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "pages" */ '@/views/Menu/MenuTree.vue')
  },
  {
    path: '/pages',
    name: 'Pages',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Pages',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pages',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "pages" */ '@/views/Pages/List.vue')
  },
  {
    path: '/pages/:id',
    name: 'Page',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Page',
      metaTags: [
        {
          property: 'og:title',
          content: 'Page',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "page-edit" */ '@/views/Pages/Page.vue')
  },
  {
    path: '/pages/section/:id',
    name: 'Section',
    includeInSiteMap: false,
    meta: { 
      authorize: ["Administrator"],
      title: 'Section',
      metaTags: [
        {
          property: 'og:title',
          content: 'Section',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "section-edit" */ '@/views/Pages/Section.vue')
  },
  {
    path: '/colors',
    name: 'Colors',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Colors',
      metaTags: [
        {
          property: 'og:title',
          content: 'Colors',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "colors" */ '@/views/Color/Colors.vue')
  },
  {
    path: '/estimate',
    name: 'Estimates',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Estimates',
      metaTags: [
        {
          property: 'og:title',
          content: 'Estimates',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "estimates" */ '@/views/Estimate/EstimateList.vue')
  },
  {
    path: '/estimate/:id',
    name: 'Edit Estimate',
    includeInSiteMap: false,
    meta: { 
      authorize: [],
      title: 'Edit Estimate',
      metaTags: [
        {
          property: 'og:title',
          content: 'Edit Estimate',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "estimates" */ '@/views/Estimate/EstimateEdit.vue')
  },
  {
    path: '/unauth',
    name: 'Unauthorized',
    includeInSiteMap: false,
    meta: { authorize: [] },
    component: () =>
      import(/* webpackChunkName: "Unauth" */ '@/views/Unauthorized.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(nearestWithMeta) {
    const tags = nearestWithMeta.meta.metaTags.concat(commonTags)
    
    // Turn the meta tag definitions into actual elements in the head.
    tags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  }

  const { authorize } = to.meta
  const currentUser = store.getters.getUser
  const roles = store.getters.getUserRoles
  
  if(authorize && authorize.length > 0) {
    if(!currentUser.LoggedIn) {
      return next({path: '/account/login', query: { returnUrl: to.path }})
    }

    let allowed = false
    roles.forEach(r => {
      if(authorize.includes(r.roleName)) {
        allowed = true
      }
    })
    if(!allowed) {
      return next({ path: '/unauth' })
    }
  }

  next()
})
export default router
