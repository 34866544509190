<template>
  <v-navigation-drawer color="menu" app v-model="drawer" temporary width="325">
    <v-list nav dense>
      <account></account>
      <div v-for="item in menuItems" v-bind:key="item.id">
        <v-list dense v-if="item.subMenuItems.length == 0">
          <v-list-item :data-cy="item.id" :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon
              >{{ item.text }}
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-list-group color="accent" :data-cy="item.id" v-else no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon
              >{{ item.text }}
            </v-list-item-icon>
          </template>
          <v-list-item
            :data-cy="subMenuItem.id"
            v-for="subMenuItem in item.subMenuItems"
            v-bind:key="subMenuItem.text"
            :to="subMenuItem.to"
          >
            <v-list-item-icon>
              <v-icon>{{ subMenuItem.icon }}</v-icon
              >{{ subMenuItem.text }}
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </div>
      <v-list-item data-cy="menu-theme" @click="onToggleTheme">
        <v-list-item-icon>
          <v-icon>mdi-theme-light-dark</v-icon> {{ theme }}
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import account from "./account"
// import colors from 'vuetify/es5/util/colors'
import store from "@/store"
import menuService from "@/_services/menu-service.js"
import router from "@/router"
import breakpointMixin from "@/mixins/breakpointMixin"
import loggedInMixin from "@/mixins/loggedInMixin"
// import registryService from "@/_services/registry-service.js"

export default {
  name: "app-drawer",

  mixins: [breakpointMixin, loggedInMixin],

  components: {
    account,
  },
  computed: {},

  created() {
    this.filterMenuItems()
  },

  data: () => ({
    drawer: false,
    group: null,
    menuItems: [],
    theme: "Light Theme",
    roles: [],
  }),

  methods: {
    onClose() {
      store.commit("toggleDrawer");
    },

    onMenuItem(to) {
      router.push(to);
    },

    onToggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      store.commit("setDark", this.$vuetify.theme.dark);
      this.theme = this.$vuetify.theme.dark ? "Light Theme" : "Dark Theme";
      // this.$vuetify.theme.themes.dark.appbar = registryService.getValue(
      //   "app_theme_dark_appbar"
      // );
      // this.$vuetify.theme.themes.light.primary = colors.green;
      // this.$vuetify.theme.themes.dark.primary = colors.purple;
      // const darkJson = JSON.stringify(this.$vuetify.theme.themes.dark);
      // console.log("Theme:", darkJson);
    },

    filterMenuItems() {
      // console.log("filterMenuItems()");
      this.menuItems = menuService.getDrawerMenu()
    }
  },

  watch: {
    drawer: function () {
      if (!this.drawer) {
        store.commit("toggleDrawer");
      }
    },
    "$store.state.menuItems": function () {
      this.filterMenuItems()
    },

    "$store.state.drawerOpen": function () {
      this.drawer = this.$store.getters.getDrawerOpen;
    },

    "$store.state.userRoles": function () {
      this.roles = this.$store.getters.getUserRoles;
      this.filterMenuItems()
    },

  },

  store,
};
</script>

<style lang="scss" scoped>
.v-icon {
  padding-right: 10px;
}
</style>
