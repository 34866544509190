import apiService from "@/_services/api-service.js"
import store from "@/store"

export default {

  load: function () {
    const url = "registry"
    apiService.get(url, this.successCallback, this.errorCallback);
  },

  successCallback(response) {
    store.commit("setRegistrySettings", response.data)
  },

  errorCallback() {
      const notification = {
        title: "Registry",
        error: "Could not retrieve Registry settings",
      };
      store.commit("setResponseError", notification);
  },

  getValue: function (key) {
    const settings = store.getters.getRegistrySettings
    let setting = settings.find(r => r.key === key)
    return setting != undefined ? setting.value : ''
  },

  getBool: function (key) {
    let value = false
    const settings = store.getters.getRegistrySettings
    let setting = settings.find(r => r.key === key)
    if(setting) {
      value = setting.value === "true" ? true : false
    }

    return value
  }

};
