import Vue from 'vue'
import Vuex from 'vuex'
import { Role, hasRole } from '@/_utility/role'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colors: [],
    drawerOpen: false,
    loadingCount: 0,
    menuItems: [],
    notification: {},
    notificationDisplay: false,
    pageId: 0,
    postItemsPerPage: 10,
    postSelectedPage: 1,
    postSelectedCategories: [],
    postSelectedTags: [],
    registrySettings: [],
    responseError: null,
    returnUrl: '',
    user: {
      email: '',
      firstname: '',
      lastname: '',
      LoggedIn: false,
      phone: '',
      userId: 0,
      username: '',
      role: 'User',
      token: '',
      expires: '',
    },
    userRoles: [],
    dark: true,
  },

  getters: {
    isAdministrator: state => {
      var administrator = false
      if (state.user.LoggedIn) {
        administrator = hasRole(state.userRoles, Role.Admin)
      }
      return administrator
    },
    isManager: state => {
      var manager = false;
      if (state.user.LoggedIn) {
        manager = hasRole(state.userRoles, Role.Admin) || hasRole(state.userRoles, Role.Manager)
      }
      return manager
    },
    isBlogger: state => {
      var blogger = false
      if (state.user.LoggedIn) {
        blogger = hasRole(state.userRoles, Role.Admin) || hasRole(state.userRoles, Role.Blogger) || hasRole(state.userRoles, Role.Manager)
      }
      return blogger
    },
    isForeman: state => {
      var foreman = false
      if (state.user.LoggedIn) {
        foreman = hasRole(state.userRoles, Role.Admin) || hasRole(state.userRoles, Role.Foreman) || hasRole(state.userRoles, Role.Manager)
      }
      return foreman
    },
    isOffice: state => {
      var office = false
      if (state.user.LoggedIn) {
        office = hasRole(state.userRoles, Role.Admin) || hasRole(state.userRoles, Role.Office) || hasRole(state.userRoles, Role.Manager)
      }
      return office
    },

    getColors: state => state.colors,
    getDrawerOpen: state => state.drawerOpen,
    getLoading: state => state.loadingCount > 0,
    getMenuItems: state => state.menuItems,
    getNotification: state => state.notification,
    getNotificationDisplay: state => state.notificationDisplay,
    getPageId: state => state.pageId,
    getPostSelectedPage: state => state.postSelectedPage,
    getPostItemsPerPage: state => state.postItemsPerPage,
    getPostSelectedCategories: state => state.postSelectedCategories,
    getPostSelectedTags: state => state.postSelectedTags,
    getUser: state => state.user,
    getUserRoles: state => state.userRoles,
    getRegistrySettings: state => state.registrySettings,
    getResponseError: state => state.responseError,
    getReturnUrl: state => state.returnUrl,
    getDark: state => state.dark,
    getToken: state => state.user.token,
  },

  mutations: {

    isLoading(state) {
      state.loadingCount++
    },

    notLoading(state) {
      state.loadingCount--
    },

    // Is the Navigation Drawer open or closed
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen
    },

    setColor(color) {
      state => {
        state.colors[state.colors.map((x, i) => [i, x]).filter(
        x => x[1].id == color.id)[0][0]] = color

        state.commit("setColors", state.colors)
      }
    },

    setColors(state, colors) {
      state.colors = colors
    },

    setDark(state, isDark) {
      state.dark = isDark
    },

    setMenuItems(state, menuItems) {
      state.menuItems = menuItems
    },

    setNotification(state, notification) {
      state.notification = notification
    },

    setNotificationDisplay(state, display) {
      state.notificationDisplay = display
    },

    setPageId(state, id) {
      state.pageId = id
    },
    
    setPostSelectedItemsPerPage(state, postItemsPerPage) {
      state.postItemsPerPage = postItemsPerPage
    },

    setPostSelectedPage(state, postSelectedPage) {
      state.postSelectedPage = postSelectedPage
    },

    setPostSelectedTags(state, selectedTags) {
      state.postSelectedTags = selectedTags
    },

    setPostSelectedCategories(state, selectedCategories) {
      state.postSelectedCategories = selectedCategories
    },

    setRegistrySettings(state, settings){
      state.registrySettings = settings
    },
    
    setResponseError(state, error) {
      state.responseError = error
    },

    setReturnUrl(state, returnUrl) {
      state.returnUrl = returnUrl
    },

    setUser(state, user) {
      state.user = user
      if (user.id == 0) {
        state.isAdministrator = false
        state.isManager = false
        state.userRoles = []
      }
    },

    setUserRoles(state, roles) {
      console.log("User roles: ", roles)
      state.userRoles = roles
      state.isAdministrator = hasRole(state.userRoles, Role.Admin)
      state.isManager = state.isAdministrator || hasRole(state.userRoles, Role.Manager)
    },
  }
})
