import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/es5/util/colors'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi",
  },

  theme: {
    dark: true,
    options: { 
      customProperties: true 
    },
    themes: {
      light: {
        accent: '#82B1FF',
        background: colors.pink,
        error: '#FF5252',
        info: '#2196F3',
        menu: colors.grey,
        primary: '#1976D2',
        secondary: colors.blue,
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        accent: colors.cyan,
        background: colors.blue.darken4,
        error: colors.red,
        info: colors.blueGrey,
        menu: colors.deepPurple,
        primary: "#1A237E",
        secondary: colors.black,
        success: colors.green,
        warning: colors.yellow,
      }
    },
  },
})

export default vuetify
