import axios from "axios"
import store from "@/store"

const x = "";

export default {
  x: x,
};

axios.interceptors.request.use(function(config) {
  const user = store.getters.getUser;
  if (user && user.token) {
    // console.log("Applying ApiKey and Token")
    config.headers.common['ApiKey'] = "C920CE1B-811B-4987-8E72-AD75EAB706DF";
    config.headers.common['Token'] = user.token;
  } 

  return config;
});
