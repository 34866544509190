<template>
  <v-snackbar color="primary"
    data-cy="notification"
    centered
    rounded="xl"
    v-model="display"
    :timeout="timeout"
  >
    <v-row v-if="notification.title && notification.title.length > 0">
      <v-col cols="12">
        <div class="title" data-cy="notification-title">
          {{ notification.title }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="message"
          v-for="message in notification.messages"
          v-bind:key="message"
          data-cy="notification-message"
        >
          {{ message }}
        </div>
      </v-col>
    </v-row>
    <v-row> </v-row>
    <v-row>
      <v-col cols="12">
        <v-spacer></v-spacer>
        <v-btn :color="notification.status"
          data-cy="notification-close-btn"
          class="close-button"
          @click="onClose"
          >Close</v-btn
        >
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import store from "@/store"
import registry from "@/_services/registry-service";

export default {
  data: () => ({
    display: false,
    color: "error",
    notification: {},
    timeout: 3000,
  }),

  methods: {
    onClose() {
      this.display = false;
    },
  },

  store,

  watch: {
    display: function () {
      console.log('Notification Displayed: ', this.display)
      store.commit("setNotification", null);
      store.commit("setResponseError", null);
      store.commit("setNotificationDisplay", this.display)
    },

    "$store.state.notification"() {
      const notification = store.getters.getNotification;
      if (notification) {
        this.notification = notification;
        this.display = true;
        this.notification.status = "success"
        store.commit("setNotificationDisplay", true);
        store.commit("setNotification", null);
      }
    },

    "$store.state.responseError"() {
      var response = store.getters.getResponseError;
      if (response) {
        if (response.error) {
          if (response.error.status) {
            if (response.error.status == 400) {
              const errors = [];
              errors.push(response.error.data.title);
              this.notification = {
                title: response.title,
                status: "error",
                messages: errors,
              };
            }
          } else {
            if (!Array.isArray(response.error)) {
              this.notification = {
                title: response.title,
                status: "error",
                messages: response.error.split(","),
              };
            } else {
              this.notification = {
                title: response.title,
                status: "error",
                messages: [],
              };
            }
          }
        }
      }
      this.display = true;
      store.commit("setNotificationDisplay", true);
      store.commit("setResponseError", null);
    },

    "$store.state.registrySettings"() {
      const setting = registry.getValue("app_snackbar_delay");
      this.timeout = parseInt(setting, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  margin-right: 10px;
  float: right;
}

.message {
  font-size: 1.5em !important;
  text-align: center;
}

.title {
  font-size: 2em !important;
  text-align: center;
}
</style>