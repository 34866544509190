import store from "@/store"

export default {
  data: function() {
    return {
      LoggedIn: false,
    };
  },

  store,
  watch: {
    "$store.state.user": function() {
      this.LoggedIn = store.getters.getUser.LoggedIn;
    },
  },
};
