<template>
  <v-app>
    <appdrawer></appdrawer>
    <appbar></appbar>

    <v-main>
      <v-container>
        <div v-if="isLoading()">
          <v-progress-linear indeterminate></v-progress-linear>
        </div>
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
    <notify></notify>
    <appfooter></appfooter>
    <bottomnav></bottomnav>
  </v-app>
</template>

<script>
import appbar from "./components/app/appbar";
import appdrawer from "@/components/app/drawer";
import appfooter from "@/components/app/footer";
import bottomnav from "./components/app/bottom";
import colorService from "@/_services/color-service";
import menuService from "@/_services/menu-service";
import notify from "@/components/controls/notification";
import router from "@/router";
import store from "@/store";
import registry from "@/_services/registry-service";
import routerService from "@/_services/router-service";

export default {
  name: "App",

  components: {
    appbar,
    appdrawer,
    appfooter,
    bottomnav,
    notify,
  },

  created() {
    console.log("App.created()");
    console.log(
      "VUE_APP_TEMPLATE_BASEURL: ",
      process.env.VUE_APP_TEMPLATE_BASEURL
    );
    registry.load();
  },

  data: () => ({
    //
  }),

  methods: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
  router,
  store,

  watch: {
    "$store.state.colors": function () {
      colorService.loadColors(this.$vuetify);
    },

    "$store.state.registrySettings": function () {
      menuService.load();
      routerService.load();
      colorService.load();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
