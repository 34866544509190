import apiService from "@/_services/api-service";
import store from "@/store"

export default {
  load() {
    apiService.get("color", this.successCallback, this.errorCallback)
  },

  loadColors(vuetify) {
    console.log("colorService.loadColors()")
    const colors = store.getters.getColors
    colors.forEach(color => {
      this.setColor(vuetify, color)
    })
  },

  successCallback(result) {
    const colors = result.data
    store.commit("setColors", colors)
  },

  errorCallback(error) {
    console.log("color-service.load() Error: ", error)
  },

  put(color) {
    const url = "color/" + color.id
    apiService.put(url, color, this.putSuccess, this.putError)
  },

  putSuccess(result) {
    store.commit("setColor", result.data)
  },

  putError(error) {
    console.log("color-service.put() Error: ", error)
  },

  setColor(vuetify, color) {
    if (color.dark) {
      switch (color.name) {
        case 'accent':
          vuetify.theme.themes.dark.accent = color.code
          break
        case 'background':
          vuetify.theme.themes.dark.background = color.code
          break
        case 'error':
          vuetify.theme.themes.dark.error = color.code
          break
        case 'info':
          vuetify.theme.themes.dark.info = color.code
          break
        case 'menu':
          vuetify.theme.themes.dark.menu = color.code
          break
        case 'primary':
          vuetify.theme.themes.dark.primary = color.code
          break
        case 'secondary':
          vuetify.theme.themes.dark.secondary = color.code
          break
        case 'success':
          vuetify.theme.themes.dark.success = color.code
          break
        case 'warning':
          vuetify.theme.themes.dark.warning = color.code
          break
      }
    } else {
      switch (color.name) {
        case 'accent':
          vuetify.theme.themes.light.accent = color.code
          break
        case 'background':
          vuetify.theme.themes.light.background = color.code
          break
        case 'error':
          vuetify.theme.themes.light.error = color.code
          break
        case 'info':
          vuetify.theme.themes.light.info = color.code
          break
        case 'menu':
          vuetify.theme.themes.light.menu = color.code
          break
        case 'primary':
          vuetify.theme.themes.light.primary = color.code
          break
        case 'secondary':
          vuetify.theme.themes.light.secondary = color.code
          break
        case 'success':
          vuetify.theme.themes.light.success = color.code
          break
        case 'warning':
          vuetify.theme.themes.light.warning = color.code
          break
      }
    }
  }
}