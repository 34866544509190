<template>
  <v-app-bar color="primary" app left v-if="breakpoint > 2">
    <v-app-bar-nav-icon
      v-if="breakpoint > 2"
      @click="onMenu"
      data-cy="app-bar-nav"
    ></v-app-bar-nav-icon>
              <img :src="`data:image/jpeg;base64,` + logo.data" height="48" width="64"/>

    <v-divider class="mx-4" vertical v-if="breakpoint > 2"></v-divider>
    <v-toolbar-title class="company">{{ company }}</v-toolbar-title
    >
    <v-divider class="mx-4" vertical></v-divider>
    <span>{{ currentRoute }}</span>
    <v-spacer></v-spacer>
    <v-toolbar-items
      class="hidden-sm-and-down"
      v-for="item in menuItems"
      v-bind:key="item.id"
    >
      <v-btn text :to="item.to" v-if="item.subMenuItems.length == 0">
        <v-icon>{{ item.icon }}</v-icon
        >{{ item.text }}
      </v-btn>
      <v-menu offset-y v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text>
            <v-icon>{{ item.icon }}</v-icon
            >{{ item.text }}
          </v-btn>
        </template>
        <v-list color="primary" v-for="subItem in item.subMenuItems" v-bind:key="subItem.id">
          <v-list-item :to="subItem.to">
            <v-list-item-title>
              <v-icon>{{ subItem.icon }}</v-icon
              >{{ subItem.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider vertical></v-divider>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import apiService from "@/_services/api-service"
import breakpointMixin from "@/mixins/breakpointMixin"
import menuService from "@/_services/menu-service.js"
import store from "@/store"
import registry from "@/_services/registry-service"

export default {
  name: "app-bar",

  created() {
    this.getLogo()
  },

  data: () => ({
    currentRoute: "Home",
    currentFrom: {},
    company: registry.getValue("app_title"),
    logo: {
      id: 0,
      name: '',
      data: []
    },
    menuItems: [],
  }),

  methods: {
    getLogo() {
      apiService.get("logo/0", this.getLogoSuccess, this.getLogoError) 
    },

    getLogoSuccess(result) {
      this.logo = result.data;
    },

    getLogoError(error){
      console.log('Get Logo Error: ', error);
    },
    
    onMenu() {
      // console.log("onMenu()");
      store.commit("toggleDrawer");
    },

    onBreakpointUpdated() {
      this.menuItems = menuService.getAppMenu(this.breakpoint);
    },
  },

  mixins: [breakpointMixin],

  store,

  watch: {
    "$store.state.registrySettings": function () {
      this.company = registry.getValue("app_title");
    },
    "$store.state.menuItems": function () {
      this.menuItems = menuService.getAppMenu(this.breakpoint);
    },
    "$store.state.userRoles": function () {
      this.menuItems = menuService.getAppMenu(this.breakpoint);
    },

    $route: function (to, from) {
      // console.log("Route changed from ", from, " to ", to);
      this.currentFrom = from
      this.currentRoute = to.name;
    },
  },
};
</script>
<style scoped>
.company {
  font-size: 2em;
}
</style>
