<template>
  <div v-if="LoggedIn">
    <v-list-item two-line :class="miniVariant && 'px-0'">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ user.firstname + " " + user.lastname }}</v-list-item-title>
        <v-list-item-subtitle v-for="role in userRoles" v-bind:key="role.id">{{ role.roleName }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import store from "@/store"
import loggedInMixin from '../../mixins/loggedInMixin'

export default {
  name: "account",

  mixins: [loggedInMixin],

  data: () => ({
    miniVariant: false,
    user: {
      email: "",
      firstname: "",
      lastname: "",
      memberId: 0,
      phone: "",
      userId: 0,
    },
    userRoles: [],
  }),

  store,
  watch: {
    "$store.state.user": function() {
      this.user = store.getters.getUser;
    },

    "$store.state.userRoles": function () {
      this.userRoles = store.getters.getUserRoles;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>

