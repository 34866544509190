<template>
  <v-bottom-navigation color="accent" v-if="display" hide-on-scroll style="height: 55px" app>
    <v-container>
      <v-row>
        <v-btn icon x-large @click="onMenu"
          ><div class="menu-item">Menu</div>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-divider class="mx-4"></v-divider>
        <div v-for="item in menuItems" v-bind:key="item.id">
          <v-btn icon x-large :to="item.to">
            <div class="menu-item">{{ item.text }}</div>
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
          <v-divider class="mx-4"></v-divider>
        </div>
      </v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
import store from "@/store"
import menuService from "@/_services/menu-service.js"

export default {
  name: "app-bottom-nav",
  components: {},
  computed: {
    display() {
      var rc = false;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          rc = true;
          break;
        case "sm":
          rc = true;
          break;
        case "md":
          rc = false;
          break;
        case "lg":
          rc = false;
          break;
        case "xl":
          rc = false;
          break;
      }
      return rc;
    },
  },

  data: () => ({
    menuItems: [],
  }),

  methods: {
    filterMenuItems() {
      this.menuItems = menuService.getBottomMenu();
    },

    onMenu() {
      store.commit("toggleDrawer");
    },
  },

  mounted() {
    this.filterMenuItems();
  },

  store: store,
  watch: {
    "$store.state.menuItems": function () {
      this.filterMenuItems()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
</style>
