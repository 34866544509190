import apiService from "@/_services/api-service.js"
import router from "@/router"

export default {

  load: function () {
    const url = "page"
    apiService.get(url, this.successCallback, this.errorCallback);
  },

  successCallback(result) {
    console.log('router-service.load() :', result.data)
    const pages = result.data
    pages.forEach(page => {
      let route = {
        component: () => import('@/views/Dynamic.vue'),
        includeInSiteMap: page.includeInSiteMap,
        name: page.name,
        path: page.path + '/:id',
        meta: {
          title: page.metaTitle,
          authorize : [],
          metaTags: [],
        }
      }
      route.meta.authorize = []
      page.authorizedRoles.forEach(role => {
        route.meta.authorize.push(role.roleName)
      })
      page.metaTags.forEach(tag => {
        const metaTag = {
          property: tag.property,
          content: tag.content
        }
        route.meta.metaTags.push(metaTag)
      })
      router.addRoute(route)
      console.log("Router: ", router);
    });
    // const route = {
    //   path: '/dynamic/:id',
    //   name: 'Dynamic',
    //   meta: { 
    //     authorize: [],
    //     title: 'Dynamic Route',
    //     metaTags: [
    //       {
    //         property: 'og:title',
    //         content: 'Dynamic Route',
    //       },
    //       {
    //         property: 'og:description',
    //         content: '',
    //       },
    //     ],
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ '../views/Dynamic.vue')
    // }
  
    // router.addRoute(route)
  },

  errorCallback(error) {
    console.log('router-service.load() Error: ', error)
  }

}