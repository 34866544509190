import axios from "axios"
import store from "@/store"
import API from "./index"

export default {
    baseURL() {
        return process.env.VUE_APP_TEMPLATE_BASEURL
    },
    
    delete(route, successCallback, errorCallback) {
        console.log(API.x)
        store.commit("isLoading");
        const url = process.env.VUE_APP_TEMPLATE_BASEURL + route
        console.log("Delete: ", url)
        return axios.delete(url)
            .then(response => {
                console.log("Delete response from ", url)
                console.log(response)
                successCallback(response)
            })
            .catch(error => {
                console.log("Delete error from ", url)
                console.log(error)
                if (error.response) {
                    errorCallback(error.response.data)
                } else {
                    errorCallback(error)
                }
            })
            .finally(() => {
                store.commit("notLoading");
            })
    },

    get(route, successCallback, errorCallback) {
        // console.log("ApiUrl: ", process.env.VUE_APP_TEMPLATE_BASEURL)
        store.commit("isLoading");
        const url = process.env.VUE_APP_TEMPLATE_BASEURL + route;
        console.log("Get: ", url)
        return axios.get(url)
            .then((response) => {
                console.log("Get response from ", url)
                console.log(response)
                successCallback(response);
            })
            .catch(error => {
                console.log("Get error from ", url)
                console.log(error)
                if (error.response) {
                    errorCallback(error.response)
                } else {
                    errorCallback(error)
                }
            })
            .finally(() => {
                store.commit("notLoading");
            })
    },

    post(route, data, successCallback, errorCallback) {
        // console.log("ApiUrl: ", process.env.VUE_APP_TEMPLATE_BASEURL)
        store.commit("isLoading");
        console.log("Post: ", data)
        const url = process.env.VUE_APP_TEMPLATE_BASEURL + route;
        return axios.post(url, data)
            .then((response) => {
                successCallback(response);
                console.log("Post response from ", url)
                console.log(response)
            })
            .catch(error => {
                if (error.response) {
                    errorCallback(error.response)
                } else {
                    errorCallback(error)
                }
                console.log("Post error:", error)
            })
            .finally(() => {
                store.commit("notLoading");
            })
    },

    put(route, data, successCallback, errorCallback) {
        store.commit("isLoading");
        console.log("Put: ", data)
        const url = process.env.VUE_APP_TEMPLATE_BASEURL + route;
        return axios.put(url, data)
            .then((response) => {
                successCallback(response);
                console.log("Put response from ", url)
                console.log(response)
            })
            .catch(error => {
                console.log("Put error from ", url)
                console.log(error)
                if (error.response) {
                    errorCallback(error.response)
                } else {
                    errorCallback(error)
                }
            })
            .finally(() => {
                store.commit("notLoading");
            })
    },
}