<template>
  <v-footer color="primary" absolute app class="font-weight-medium">
    <v-container class="container">
      <v-row align="center" justify="center">
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <v-row align="center" justify="center">
            <v-icon>mdi-copyright</v-icon> {{ company }} {{ year }}
            {{ version }}
              <v-icon dense left @click="onRefresh()">mdi-refresh</v-icon>
          </v-row>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <v-row align="center" justify="center">
            <v-icon>mdi-facebook</v-icon>Social Icons
          </v-row>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <v-row align="center" justify="center">
            <v-icon>mdi-sitemap</v-icon>
            <div @click="onSiteMap">Site Map</div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import breakpointMixin from "../../mixins/breakpointMixin"
import colorService from "@/_services/color-service"
import registry from "@/_services/registry-service"
import menuService from '@/_services/menu-service'
import registryService from '@/_services/registry-service'
import routerService from '@/_services/router-service'
import store from "@/store"

export default {
  name: "app-footer",

  components: {},

  created() {},

  data: () => ({
    copyright: String.fromCharCode(184),
    company: registry.getValue("app_title"),
    displayRefresh: store.getters.isAdministrator,
    version: registry.getValue("app_version"),
    mode: process.env.VUE_APP_TITLE,
    year: new Date().getFullYear(),
  }),

  mixins: [breakpointMixin],

  methods: {
    onRefresh() {
    menuService.load()
    registryService.load()
    routerService.load()
    colorService.load()
    },
    // getRoutesList(routes, pre) {
    //   return routes.reduce((array, route) => {
    //     const path = `${pre}${route.path}`;

    //     if ((route.path !== '*') && (typeof route.includeInSiteMap === 'undefined' )) {
    //       array.push(path);
    //     } else {
    //       console.log("Excluding: ", route.path)
    //     }

    //     if (route.children) {
    //       array.push(...this.getRoutesList(route.children, `${path}/`));
    //     }

    //     return array;
    //   }, []);
    // },

    onSiteMap() {
      console.log("onSiteMap()");
      // const list = this.getRoutesList(router.options.routes, "http://aquaductroofing.com")
      //   .map(route => `<url><loc>${route}</loc></url>`)
      //   .join('\r\n');
      // const siteMap = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
      //   ${list}
      //   </urlset>`

      // var blob = new Blob([siteMap], { type: 'application/xml' })
      // var a = document.createElement('a');
      // a.download = 'sitemap.xml'
      // a.href = URL.createObjectURL(blob)
      // a.dataset.downloadurl = ['application/xml', a.download, a.href].join(':')
      // a.style.display = "none"
      // document.body.appendChild(a)
      // a.click()
      // document.body.removeChild(a)
      // setTimeout(function() {URL.revokeObjectURL(a.href); }, 1500)
    },
  },

  watch: {
    "$store.state.userRoles": function () {
      this.displayRefresh = store.getters.isAdministrator
    },

    "$store.state.registrySettings": function () {
      this.company = registry.getValue("app_title");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.container {
  width: 100%;
}

@media (min-width: 1264px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .container {
    width: 100%;
  }
}
</style>
